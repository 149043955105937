import styled from 'styled-components'
import {device} from '../../../../shared-ui/theme'

export const FeaturedServicesWrap = styled.section`
    padding-top: 100px;
    padding-bottom: 63px;    
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 42px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 22px;
    }
`;

export const GalleryWrap = styled.section`
    background: #f8f8f8;
    padding-top: 100px;
    padding-bottom: 63px;    
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 42px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 22px;
    }
`;

export const GalleryBottom = styled.div`
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.40;
    color: #333333;
`;

export const ServiceWrap = styled.div`
    padding-bottom: 100px;
    @media ${device.medium}{
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-bottom: 60px;
    }
    .box-item{
        margin-bottom: 50px;
    }
`;

export const ServiceBottom = styled.div`
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.40;
    color: #333333;
`;

export const IntroWrap = styled.section`
    padding-top: 100px;
    padding-bottom: 92px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 72px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 52px;
    }
`;

export const IntroHeading = styled.div``;

export const IntroText = styled.div``;

export const PricingWrap = styled.section`
    background: #f8f8f8;
    padding-top: 100px;
    padding-bottom: 70px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 50px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 30px;
    }
`; 

export const LocationsWrap = styled.div `
    background: #f8f8f8;
    padding-top: 100px;
    padding-bottom: 63px;    
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 42px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 22px;
    }
`;


export const LocationBoxWrap = styled.div `
    position: relative;
    @media ${device.medium}{
        margin-top: 30px;
    }
    img {
        border-radius: 5px;
    }
`;
